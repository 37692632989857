import axios from 'axios';
import Vue from 'vue';

export default {
  methods: {
    async safeDeleteUser(user) {
      const canBeDeleted = await this.confirmUserDeletion(user);
      return canBeDeleted ? this.deleteUser(user) : false;
    },
    async confirmUserDeletion(user) {
      try {
        const { data: { owned } } = await axios.get(`v1/therapists/${user.id}/patients/totals`);
        if (owned) {
          this.$bvModal.msgBoxOk(`User ${user.username} owns ${owned} patient. Please transfer them first`);
          return false;
        }
        return await this.$bvModal.msgBoxConfirm(`Are you sure to delete user ${user.username} ?`);
      } catch {
        return false;
      }
    },
    async deleteUser(user) {
      try {
        await axios.delete(`v1/users/${user.id}`);
        Vue.prototype.$noty.success(`User '${user.username}' successfully deleted`);
        return Promise.resolve(user);
      } catch (e) {
        Vue.prototype.$noty.error(`Something went wrong deleting user '${user.username}'`);
        console.error(`[delete-user] Error deleting user ${user.id}`, e);
        return Promise.reject(e);
      }
    },
  },
};
