<template>
  <b-container id="professionals-list-module" fluid>
    <div class="d-flex pb-3 align-items-center justify-content-between">
      <h5 class="mb-0">Professionals List</h5>
      <input class="col-3 form-control" v-model="searchByUsername" type="text"
             placeholder="Search by username"
             aria-label="Search">
    </div>
    <!-- Professional users listing -->
    <table class="table users-list-table mx-0 mb-2">
      <thead class="thead-light">
      <tr class="users-list-table__headings">
        <th scope="col">ID</th>
        <th scope="col">Username</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col">Type</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr class="users-list-table__item"
          v-for="(professional, index) in professionals"
          :key="index">
        <td class="align-middle"> {{ professional.id }}</td>
        <td class="align-middle"> {{ professional.username }}</td>
        <td class="align-middle"> {{ professional.firstname }} {{ professional.lastname }}</td>
        <td class="align-middle"> {{ professional.email }}</td>
        <td class="align-middle"> {{ professional.phone ? professional.phone.number : '-' }}</td>
        <td class="align-middle">{{ getProfessionalType(professional) }}</td>
        <td class="align-middle">
          <template v-if="professional.deleted_at">
            <b-badge variant="secondary">deleted</b-badge>
          </template>
          <template v-else>
            <b-button v-b-tooltip.hover title="Edit" variant="light" class="mr-2"
                      size="sm" :to="{ name: 'ProfessionalEditDeprecated', params: { id: professional.id }}">
              <feather type="edit-2"></feather>
            </b-button>

            <b-button v-b-tooltip.hover title="Edit" variant="light" class="mr-2" v-if="$isSudo"
                      size="sm" :to="{ name: 'ProfessionalEdit', params: { id: professional.id }}">
              <feather type="edit"></feather>
            </b-button>

            <b-button v-b-tooltip.hover title="Delete" variant="light" class="mr-2"
                      size="sm" @click="tryUserDelete(professional)">
              <feather type="trash"></feather>
            </b-button>
          </template>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Pagination -->
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loadingPage"
                      @change="changePage"
                      v-model="pagination.currentPage"
                      :total-rows="pagination.totalRows"
                      :per-page="pagination.itemsPerPage"
                      size="md">
        </b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios';


// Mixins
import deleteUserActions from '@/mixins/lists/delete-user-action';
import pagination from '@/mixins/lists/pagination';

export default {
  name: 'ProfessionalsList',
  mixins: [ deleteUserActions, pagination ],
  data() {
    return {
      users: [],
      searchByUsername: '',
      loadingPage: false,
      role: '',
      total: 0,
      professionals: [],
      searchTimeout: null,
    };
  },
  mounted() {
    this.requestUsers();
  },
  methods: {
    tryUserDelete(user) {
      this.safeDeleteUser(user).then(() => this.requestUsers());
    },
    onPageChange() {
      this.requestUsers();
    },
    requestUsers() {
      this.loadingPage = true;
      const page = this.pagination.currentPage - 1;
      const query = [
        `limit=${this.pagination.itemsPerPage}`, `offset=${page * this.pagination.itemsPerPage}`, 'role=Professional',
      ];
      if (this.searchByUsername) {
        query.push(`search=${this.searchByUsername}`);
      }
      axios.get(`v1/users?${query.join('&')}`, { params: { client: 'back-office' } }).then(response => {
        this.professionals = response.data.users;
        this.pagination.totalRows = response.data.total;
      }).catch(err => {
        this.$noty.error('Failed to fetch users: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    getProfessionalType(professional) {
      try {
        let professionalItem = {};
        if (professional.professionals && professional.professionals[0]) {
          [ professionalItem ] = professional.professionals;
        } else if (professional.therapist_configs) {
          professionalItem = professional.therapist_configs;
        } else {
          return 'N/A';
        }

        const treatmentFlow = professionalItem.treatment_flow;
        const intakeFlow = professionalItem.intake_flow;

        if (treatmentFlow && intakeFlow) {
          return 'Treatment & Intake';
        }

        return treatmentFlow ? 'Treatment' : 'Intake';
      } catch (e) {
        console.error(e);
        return 'N/A';
      }
    },
  },
  watch: {
    searchByUsername() {
      clearTimeout(this.searchTimeout);
      setTimeout(() => {
        this.requestUsers();
      }, 400);
    },
    role() {
      this.requestUsers();
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/_user-tables";
</style>
